import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "./app/pages/Login";
import RequiredAuth from "./app/components/RequiredAuth";
import HomeApp from "./app/pages/HomeApp";
import Logout from "./app/pages/Logout";
import ThankYouPage from "./app/pages/ThankYouPage";
import "keen-slider/keen-slider.min.css";
import "./App.scss";
import ForgetPassword from "./app/pages/ForgetPassword";
import ResetPassword from "./app/pages/ResetPassword";
import ResetPasswordDone from "./app/pages/ResetPasswordDone";
import { titles } from "./utility/constants/constants";
const Router = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = titles[location.pathname.split("/")[1]]
      ? `${
          titles[location.pathname.split("/")[1]]
        } | Enterprise Legal Management`
      : "Legistify Account | Enterprise Legal Management";
  }, [location]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-password-done" element={<ResetPasswordDone />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/" element={<RequiredAuth />}>
        <Route index element={<HomeApp />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace={true} />} />
    </Routes>
  );
};

export default Router;
