import React, { useState } from "react";
import { constants } from "../../utility/constants/constants";
import Container from "../components/Container";
import CompanyLogo from "../../Assets/icons/logo_large_black.svg";
import { Spinner } from "reactstrap";
import axios from "../../axios/axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { handleError } from "../../axios/handleError";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(constants.FORGET_PASSWORD, { email: email.trim() }, { headers: { "Content-Type": "application/json" } });
      if (response.status === 200) {
        setComplete(true);
      } else toast.error("Email Not Exist!");
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };

  return (
    <Container>
      <div className="m-3 m-md-5 w-100">
        <img className="mb-4 cursor-pointer" onClick={() => window.open(constants.LANDING_PAGE_URL)} src={CompanyLogo} alt="logo" />
        <p className="h4 mb-4">Forgot Password</p>
        {complete ? (
          <>
            <p className="text-success fs-5">👍 An email containing a link to reset the password has been sent to you.</p>
            <p className="text-danger fs-6">NOTE: link will only be valid for one hour, after which you will need to request a new link to reset your password..</p>
            <Link className="fs-5" to="/login">
              Back to login
            </Link>
          </>
        ) : (
          <form className="d-flex flex-column justify-content-between" onSubmit={handleOnSubmit}>
            <div className="mb-4">
              <input autoFocus required type="email" pattern="^\S+@\S+\.\S+$" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Registered Email" className="form-control" />
            </div>
            <button disabled={loading} type="submit" className="btn btn-primary">
              {loading ? <Spinner size="sm" className="m-1" /> : "SUBMIT"}
            </button>
          </form>
        )}
      </div>
    </Container>
  );
};

export default ForgetPassword;
