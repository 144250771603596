import toast from "react-hot-toast";

export const handleError = async (error) => {
  if (error.response) {
    try {
      toast.error(error?.response?.data?.msg ?? "Server Side Error!!");
    } catch (e) {
      toast.error("Server side error!");
    }
  } else if (error.message) {
    if (error.message === "Network Error") {
      toast.error("Network Error 🚀 Please Check your Network Connection!");
    } else toast.error("Something went wrong!");
  } else toast.error("Something went wrong!");
};
