import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import axios from "../../axios/axios";


import CompanyLogo from "../../Assets/icons/logo_large_black.svg";
import { handleError } from "../../axios/handleError";
import {
  authRegex,
  constants,
  loginPreferenceOptions,
} from "../../utility/constants/constants";
import Container from "../components/Container";
import CustomSwitch from "../components/CustomSwitch/CustomSwitch";
import InputPassword from "../components/InputPassword";

const Login = () => {
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [sendOtpForLoading, setSendOtpLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const navigate = useNavigate();
  const [loginPreference, setLoginPreference] = useState(
    localStorage.getItem("loginPreference") ?? "pass"
  );

  const [counter, setCounter] = useState(0);

  const productUrls = {
    litigation: process.env.REACT_APP_LITIGATION_FRONTEND_URL,
    contract: process.env.REACT_APP_CONTRACT_FRONTEND_URL,
    ipr: process.env.REACT_APP_IPR_FRONTEND_URL,
    notice: process.env.REACT_APP_NOTICE_FRONTEND_URL,
    advisory: process.env.REACT_APP_ADVISORY_FRONTEND_URL,
    arbitration: process.env.REACT_APP_ARBITRATION_FRONTEND_URL,
  };

  const redirectToApp = (id) => {
    const url = productUrls[id];
    window.location.replace(url);
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const authCookie = document.cookie.replace(authRegex, "$1");

  useEffect(() => {
    if (authCookie === "true") {
      if (searchParams.get("redirect_url") && searchParams.get("service"))
        navigate(
          "/?redirect_url=" +
            searchParams.get("redirect_url") +
            "&service=" +
            searchParams.get("service")
        );
      else navigate("/");
    } else
      document.cookie = `auth=; Path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendOtp = async () => {
    if (counter) {
      toast.error(`You can resend after ${counter} seconds`);
      return;
    }
    if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
      toast.error("Enter Valid Email");
      return;
    }
    setSendOtpLoading(true);
    try {
      const response = await axios.post(constants.SEND_OTP, {
        email: email.trim(),
      });
      if (response?.data?.status) {
        setOtpStatus(response.data.status);
        toast.success("OTP Sent!");
        setCounter(60);
      } else toast.error("Invalid Email Id!!");
    } catch (err) {
      handleError(err);
    }
    setSendOtpLoading(false);
  };

  const emailKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!otpStatus) sendOtp();
    }
  };

  const handlePassSubmit = async (e) => {
    e.preventDefault();
    const data = { email: email.trim(), password };
    setLoginLoading(true);
    try {
      const response = await axios.post(constants.LOGIN_USER, data, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (response.status === 200) {
        localStorage.setItem("loginPreference", loginPreference);
        if (response?.data?.data?.pinnedProduct) {
          return redirectToApp(response?.data?.data?.pinnedProduct);
        } else if (response?.data?.data?.accessToProducts?.length === 1) {
          return redirectToApp(response?.data?.data?.accessToProducts[0]);
        }

        if (searchParams.get("redirect_url") && searchParams.get("service"))
          return navigate(
            "/?redirect_url=" +
              searchParams.get("redirect_url") +
              "&service=" +
              searchParams.get("service")
          );

        if (response?.data?.data?.pinnedProduct === "") {
          return navigate("/");
        }
      } else toast.error("Invalid OTP!!");
    } catch (err) {
      handleError(err);
    }
    setLoginLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otpStatus) return;
    setLoginLoading(true);
    try {
      const response = await axios.post(
        constants.LOGIN_USER,
        { email: email.trim(), otp },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        localStorage.setItem("loginPreference", loginPreference);
        if (response?.data?.data?.pinnedProduct) {
          return redirectToApp(response?.data?.data?.pinnedProduct);
        } else if (response?.data?.data?.accessToProducts?.length === 1) {
          return redirectToApp(response?.data?.data?.accessToProducts[0]);
        }
        if (searchParams.get("redirect_url") && searchParams.get("service"))
          return navigate(
            "/?redirect_url=" +
              searchParams.get("redirect_url") +
              "&service=" +
              searchParams.get("service")
          );
        if (response?.data?.data?.pinnedProduct === "") {
          return navigate("/");
        }
      } else toast.error("Invalid OTP!!");
    } catch (err) {
      handleError(err);
    }
    setLoginLoading(false);
  };

  const handlePreference = (value) => {
    setLoginPreference(value);
    setEmail("");
    setOtp("");
    setPassword("");
    setOtpStatus(false);
  };

  const handleSSOSubmit = async (e) => {
    e.preventDefault();
    setSendOtpLoading(true);
    try {
      const response = await axios.get(
        `${constants.SSO_LOGIN}?email=${email}&authType=activeDirectorySAML`
      );
      if (response?.data?.status) {
        localStorage.setItem("loginPreference", loginPreference);
        if (response.data.data && response.data.data.loginUrl)
          window.location.replace(
            `${response.data.data.loginUrl}?userEmail=${email}`
          );
      }
    } catch (err) {
      handleError(err);
    }
    setSendOtpLoading(false);
  };

  return (
    <Container>
      <div className="m-3 m-md-5 w-100">
        <img
          className="mb-4 cursor-pointer"
          onClick={() => window.open(constants.LANDING_PAGE_URL)}
          src={CompanyLogo}
          alt="logo"
        />
        <p className="h5 mb-4 fw-900">Login to access LegisTrak</p>
        <CustomSwitch
          className="mb-4"
          options={loginPreferenceOptions}
          name="loginPreference"
          id="loginPreference"
          value={loginPreference}
          onChange={handlePreference}
        />
        {loginPreference === "otp" && (
          <form
            className="d-flex flex-column justify-content-between"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="mb-4 position-relative">
              <input
                autoFocus
                placeholder="Registered Email"
                name="email"
                id="email"
                type="email"
                value={email}
                disabled={otpStatus}
                onKeyDown={emailKeyDown}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
                autoComplete="off"
              />
            </div>
            {!otpStatus && (
              <button
                onClick={sendOtp}
                className="btn btn-primary"
                type="button"
                disabled={sendOtpForLoading}
              >
                {sendOtpForLoading ? (
                  <Spinner size="sm" className="m-1" />
                ) : (
                  "NEXT"
                )}
              </button>
            )}
            {otpStatus && (
              <>
                <div className="mb-4">
                  <input
                    placeholder="Enter OTP"
                    autoFocus
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                  <div className="d-flex justify-content-end">
                    {sendOtpForLoading ? (
                      <Spinner size="sm" className="mt-1" />
                    ) : (
                      <p
                        className="mb-0 text-primary fs-6 cursor-pointer"
                        onClick={() => sendOtp()}
                      >
                        Resend OTP
                      </p>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary mt-1"
                  disabled={loginLoading}
                >
                  {loginLoading ? (
                    <Spinner size="sm" className="m-1" />
                  ) : (
                    "LOGIN"
                  )}
                </button>
              </>
            )}
          </form>
        )}
        {loginPreference === "pass" && (
          <form
            className="d-flex flex-column justify-content-between"
            onSubmit={handlePassSubmit}
          >
            <div className="mb-4">
              <input
                autoFocus
                required
                type="email"
                autoComplete="off"
                pattern="^\S+@\S+\.\S+$"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Registered Email"
                className="form-control"
              />
            </div>
            <div className="mb-4">
              <InputPassword
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="form-control"
              />
              <p
                style={{ width: "fit-content" }}
                className="ms-auto text-end mb-0 text-primary cursor-pointer"
                onClick={() => navigate("/forget-password")}
              >
                Forgot Password
              </p>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loginLoading}
            >
              {loginLoading ? <Spinner size="sm" className="m-1" /> : "LOGIN"}
            </button>
          </form>
        )}
        {loginPreference === "sso" && (
          <form
            className="d-flex flex-column justify-content-between"
            onSubmit={handleSSOSubmit}
          >
            <div className="mb-4 position-relative">
              <input
                autoComplete="off"
                autoFocus
                placeholder="Registered Email"
                name="email"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loginLoading}
            >
              {loginLoading ? <Spinner size="sm" className="m-1" /> : "NEXT"}
            </button>
          </form>
        )}
      </div>
    </Container>
  );
};

export default Login;
