import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "keen-slider/keen-slider.min.css";
import "./App.scss";
import Router from "./Router";
import { constants } from "./utility/constants/constants";

function App() {
  return (
    <BrowserRouter>
      <Router />
      <Toaster position={"top-right"} toastOptions={{ className: "react-hot-toast" }} />
      {constants.ENV === "development" && <p className="dev-env">Testing Environment</p>}
    </BrowserRouter>
  );
}

export default App;
