import React, { useState } from "react";
import Confetti from "react-confetti";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import CompanyLogo from "../../Assets/icons/logo_large_black.svg";
import { axiosStatic } from "../../axios/axios";
import { handleError } from "../../axios/handleError";
import {
  constants,
  emailRegex,
  sliderArray,
} from "../../utility/constants/constants";
import Container from "../components/Container";

import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Logout = () => {
  const emailCookie = document.cookie.replace(emailRegex, "$1");

  const [sliderData, setSliderData] = useState(9);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(decodeURIComponent(emailCookie) ?? "");
  const [phone, setPhone] = useState("");
  const [feedback, setFeedback] = useState("");

  const [loading, setLoading] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      setErrors((errors) => ({ ...errors, email: true }));
      error = true;
    } else setErrors((errors) => ({ ...errors, email: false }));
    if (!feedback) {
      setErrors((errors) => ({ ...errors, feedback: true }));
      error = true;
    } else setErrors((errors) => ({ ...errors, feedback: false }));

    const phoneNumber = parsePhoneNumber(phone);
    if (
      phone &&
      phoneNumber &&
      phoneNumber.isValid() &&
      phoneNumber.isPossible()
    ) {
      setErrors((errors) => ({ ...errors, phone: false }));
    } else {
      setErrors((errors) => ({ ...errors, phone: true }));
      error = true;
    }

    if (error) return;

    const data = { recommend: sliderData, email, feedback, phone };

    setLoading(true);
    try {
      const result = await axiosStatic.post(constants.FEEDBACK, data);
      if (result?.data?.status === "success") {
        console.log(result);
        toast.success("Thank you for sharing your valuable feedback");
        setEmail(decodeURIComponent(emailCookie) ?? "");
        setErrors({});
        setFeedback("");
        setPhone("");
        setVisible(true);
      } else toast.error("Some error occurred. Please try again.");
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };
  const [visible, setVisible] = useState(false);

  return (
    <div className="position-relative vh-100">
      {visible && <Confetti className="vw-100 vh-100 overflow-hidden" />}
      <Container>
        <div className="m-2 m-md-4 w-100">
          <img
            className="mb-4 cursor-pointer"
            onClick={() => window.open(constants.LANDING_PAGE_URL)}
            src={CompanyLogo}
            alt="logo"
          />
          <p className="h6 mb-2">How likely are you to recommend us?</p>
          <form
            className="d-flex flex-column gap-3 justify-content-between"
            onSubmit={handleOnSubmit}
            autoComplete="off"
          >
            <div>
              <div className="d-flex gap-1 justify-content-md-between flex-wrap">
                {sliderArray.map((item) => (
                  <div
                    key={item.color}
                    style={{
                      color: item.count !== sliderData ? item.color : "#fff",
                      background:
                        item.count === sliderData
                          ? item.color
                          : item.color + 22,
                      transform: item.count === sliderData && "scale(1.1)",
                    }}
                    onClick={() => setSliderData(item.count)}
                    className={
                      "cursor-pointer slider-counts d-flex justify-content-center align-items-center"
                    }
                  >
                    {item.count}
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0 text-danger fs-sm">Less Likely</p>
                <p className="mb-0 text-success fs-sm">Very Likely</p>
              </div>
            </div>
            <div>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="form-control"
              />
              {errors.email && (
                <p className="text-danger fs-sm mb-0">
                  Please enter your email address
                </p>
              )}
            </div>
            <div>
              <PhoneInput
                international
                placeholder="Mobile Number"
                className="input_form_class form-control"
                id="phoneNumber"
                value={phone}
                onChange={setPhone}
                defaultCountry="IN"
                autoComplete="new-password"
              />
              {errors.phone && (
                <p className="text-danger fs-sm mb-0">
                  Please enter your phone number
                </p>
              )}
            </div>
            <div>
              <textarea
                autoFocus
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Additional Feedback"
                rows="4"
                className="form-control"
              />
              {errors.feedback && (
                <p className="text-danger fs-sm mb-0">
                  Please enter your feedback
                </p>
              )}
            </div>
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? (
                <Spinner size="sm" className="m-1" />
              ) : (
                "Submit Response"
              )}
            </button>
            <p className="text-muted fs-sm text-center">
              Your feedback is valuable to us.
              <br /> We will send you an amazon gift voucher on your first
              submission as a token of our appreciation!
            </p>
          </form>
        </div>
      </Container>
      <Link
        to={"/login"}
        className="position-absolute"
        style={{ bottom: "20px", left: "50%", transform: "translate(-50%,0)" }}
      >
        Login to LegisTrak again
      </Link>
    </div>
  );
};

export default Logout;
