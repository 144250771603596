import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, Row } from "reactstrap";
import "./index.scss";

import Litigation from "../../Assets/icons/Litigation.svg";
import Notice from "../../Assets/icons/Notice.svg";
import IPR from "../../Assets/icons/IPR.svg";
import Contract from "../../Assets/icons/Contracts.svg";
import Advisory from "../../Assets/icons/Clause Library.svg";
import Arbitration from "../../Assets/icons/Followers.svg";
import CheckSquare from "../../Assets/icons/select.png";

import axios from "../../axios/axios";
import { constants } from "../../utility/constants/constants";
import SingleCard from "../components/SingleCard";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../axios/handleError";
import pinIcon from "../../Assets/icons/pinIcon.png";
import pin_icon from "../../Assets/icons/pin_icon.png";
import pin_fill_icon from "../../Assets/icons/pin_fill_icon.png";

/**
 * It fetches user data from the backend and then renders a modal with a list of products.
 * @returns A modal with a backdrop.
 */
const HomeApp = () => {
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState([]);

  const navigate = useNavigate();
  const [currentPinId, setCurrentPinId] = useState("");

  const handlePinClick = async (e, id) => {
    e.stopPropagation();
    setCurrentPinId(id);
    try {
      const response = await axios.post(
        constants.PIN_PRODUCT,
        {
          pinnedProduct: id,
        },
        { withCredentials: true }
      );
    } catch (err) {}
  };

  const data = [
    {
      id: "litigation",
      image: Litigation,
      name: "Litigation Management ",
      description:
        "Track & manage all your matters, lawyers, budget, hearings and more",
      url: process.env.REACT_APP_LITIGATION_FRONTEND_URL,
    },
    {
      id: "contract",
      image: Contract,
      name: "Contract Management",
      description:
        "Manage full contract lifecycle from drafting till execution to tracking obligations",
      url: process.env.REACT_APP_CONTRACT_FRONTEND_URL,
    },
    {
      id: "ipr",
      image: IPR,
      name: "IPR Management",
      description:
        "Track, protect & manage all your IP portfolio and get regular updates",
      url: process.env.REACT_APP_IPR_FRONTEND_URL,
    },
    {
      id: "notice",
      image: Notice,
      name: "Notice Management",
      description:
        "Track & manage all your incoming and outgoing legal notices effectively",
      url: process.env.REACT_APP_NOTICE_FRONTEND_URL,
    },
    {
      id: "advisory",
      image: Advisory,
      name: "Advisory",
      description:
        "Track relevant standard documents and manage necessary actions",
      url: process.env.REACT_APP_ADVISORY_FRONTEND_URL,
    },
    {
      id: "arbitration",
      image: Arbitration,
      name: "Arbitration",
      description:
        "Store, track & manage arbitration orders, judgements & docs in one window",
      url: process.env.REACT_APP_ARBITRATION_FRONTEND_URL,
    },
    {
      id: "collections",
      image: CheckSquare,
      name: "Collections Management",
      description: "Automated omni channel communications platform for  debt recovery & collections",
      url: process.env.REACT_APP_COLLECTIONS_FRONTEND_URL,
    },
  ];

  const subsetData = data?.filter((item) => user?.includes(item.id));
  // Filter the data array to exclude items from the subset
  const remainingData = data?.filter((item) => !user?.includes(item.id));
  // Concatenate the subsetData and remainingData arrays
  const orderedData = subsetData?.concat(remainingData);

  const productUrls = {
    litigation: process.env.REACT_APP_LITIGATION_FRONTEND_URL,
    contract: process.env.REACT_APP_CONTRACT_FRONTEND_URL,
    ipr: process.env.REACT_APP_IPR_FRONTEND_URL,
    notice: process.env.REACT_APP_NOTICE_FRONTEND_URL,
    advisory: process.env.REACT_APP_ADVISORY_FRONTEND_URL,
    arbitration: process.env.REACT_APP_ARBITRATION_FRONTEND_URL,
    collections:process.env.REACT_APP_COLLECTIONS_FRONTEND_URL
  };

  const redirectToApp = (id) => {
    const url = productUrls[id];
    if(url){

      window.location.replace(url);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(constants.GET_USER_DATA, {
          withCredentials: true,
        });
        if (response?.data?.status)
          if (response.data.data.accessToProducts?.length === 1) {
            redirectToApp(response.data.data.accessToProducts[0]);
          }
        if (response.data.data.pinnedProduct) {
          redirectToApp(response.data.data.pinnedProduct);
        }

        setUser(response.data.data.accessToProducts);
      } catch (err) {
        handleError(err);
        document.cookie = `auth=; Path=/;domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        navigate("/login");
        console.log(err);
      }
      setLoader(false);
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="disabled-backdrop-modal">
      {!loader && (
        <Fragment>
          <Modal
            isOpen={true}
            className={"modal-dialog-centered  modal-fullscreen"}
            backdrop={false}
          >
            <ModalBody className="d-flex justify-content-center align-items-center h-100">
              <div>
                <div className="text-center">
                  <h3 style={{ color: "#223647" }}>Welcome To LegisTrak!</h3>
                  <p className=" text-muted mb-1" style={{ color: "#808E99" }}>
                    Please select from the options below to enter the platform
                  </p>

                  <div className="d-flex align-item-center justify-content-center mt-1 mb-5 ">
                    <p className="m-0 me-25"> Click on the </p>
                    <img src={pinIcon} width={20} />
                    <p className="m-0 ms-25">to set a default app to open</p>
                  </div>
                  {/* <p className=" text-center  mb-5">every time you login </p> */}
                </div>
                <div className="home_menu_container">
                  <Row
                    style={{ margin: "auto 109px" }}
                    className="justify-content-center gap-4"
                  >
                    {orderedData?.map((item) => {
                      const access = user ? user?.includes(item.id) : false;
  
                      // const access = true;
                      if (!access && item.name == "Advisory") {
                        return;
                      }
                      if (!access && item.name == "Arbitration") {
                        return;
                      }
                      return (
                        <SingleCard
                          handlePinClick={handlePinClick}
                          currentPinId={currentPinId}
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          description={item.description}
                          image={item.image}
                          url={item.url}
                          locked={!access}
                        />
                      );
                    })}
                  </Row>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <div className="modal-backdrop fade show"></div>
        </Fragment>
      )}
    </div>
  );
};

export default HomeApp;
