import React, { useEffect, useState } from "react";
import { constants } from "../../utility/constants/constants";
import Container from "../components/Container";
import CompanyLogo from "../../Assets/icons/logo_large_black.svg";
import { Spinner } from "reactstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../axios/axios";
import { handleError } from "../../axios/handleError";
import toast from "react-hot-toast";
import InputPassword from "../components/InputPassword";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (password === "") setPasswordError("");
    else if (password.length < 8) setPasswordError("Password must be at least 8 characters long");
    else if (!/(?=.*?[A-Z])/.test(password)) setPasswordError("Password must contain at least one uppercase letter");
    else if (!/(?=.*?[a-z])/.test(password)) setPasswordError("Password must contain at least one lowercase letter");
    else if (!/(?=.*?[0-9])/.test(password)) setPasswordError("Password must contain at least one number");
    else if (!/(?=.*?[#?!@$%^&*-])/.test(password)) setPasswordError("Password must contain at least one special character");
    else setPasswordError("");
  }, [password]);

  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();
    if (passwordError) return;
    if (password !== rePassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(constants.RESET_PASSWORD, { newPassword: password }, { headers: { token } });
      if (response.status === 200) {
        navigate("/reset-password-done");
      }
    } catch (err) {
      handleError(err);
      setSearchParams("token", "");
    }
    setLoading(false);
  };

  return (
    <Container>
      <div className="m-3 m-md-5 w-100">
        <img className="mb-4 cursor-pointer" onClick={() => window.open(constants.LANDING_PAGE_URL)} src={CompanyLogo} alt="logo" />
        <p className="h4 mb-4">Reset Password</p>
        {!token ? (
          <p className="text-danger fs-5">❌ Link is not Valid Or Expired!</p>
        ) : (
          <form onSubmit={resetPassword}>
            <small className="text-muted">
              <span className="fw-bolder text-black">Password Tips: </span>
              Minimum eight characters, at least one uppercase, one lowercase, one number , one special character
            </small>
            <div className="mb-4 mt-2">
              <InputPassword autoFocus required value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New Password" className="form-control" />
              {passwordError && <p className="text-danger fs-6">{passwordError}</p>}
            </div>
            <div className="mb-4">
              <InputPassword required value={rePassword} onChange={(e) => setRePassword(e.target.value)} placeholder="Re-Enter New Password" className="form-control" />
            </div>
            <button disabled={loading} type="submit" className="btn btn-primary w-100">
              {loading ? <Spinner size="sm" className="m-1" /> : "Submit"}
            </button>
          </form>
        )}
      </div>
    </Container>
  );
};

export default ResetPassword;
