import React from "react";
import CarouselComponent from "./CarouselComponent";

const Container = ({ children }) => {
  return (
    <div className="background_container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="custom-card d-flex flex-row justify-content-between">
        {children}
        <CarouselComponent />
      </div>
    </div>
  );
};

export default Container;
