import classNames from "classnames";
import React from "react";
import "./index.scss";

const CustomSwitch = React.forwardRef((props, ref) => {
  const { options, name, id, value, onChange, disabled, className, ...restProps } = props;

  return (
    <div className={`main-container ${className ?? ""}`}>
      {options.map((item) => (
        <label key={item.value} htmlFor={`${name}${item.value}`} className={classNames("switch-label", { "switch-selected-label": value === item.value })}>
          {item.label}
          <input disabled={disabled} {...restProps} onChange={(e) => onChange(e.target.value)} id={`${name}${item.value}`} style={{ display: "none" }} type="radio" name={name} value={item.value} />
        </label>
      ))}
    </div>
  );
});
export default CustomSwitch;
