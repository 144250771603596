import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";

const InputPassword = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="position-relative">
      <input {...props} type={visible ? "text" : "password"} />
      <div onClick={() => setVisible(!visible)} className="cursor-pointer position-absolute py-1 px-3" style={{ right: 0, top: 0 }}>
        {visible ? <EyeOff size={18} /> : <Eye size={18} />}
      </div>
    </div>
  );
};

export default InputPassword;
