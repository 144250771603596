import React, { useState } from "react";
import { Col } from "reactstrap";
import Lock from "../../Assets/icons/Lock.svg";
import { useSearchParams } from "react-router-dom";
import { constants } from "../../utility/constants/constants";
import axios from "../../axios/axios";
import { handleError } from "../../axios/handleError";
// import pinIcon1 from "../../Assets/icons/pinIcon1.png";
// import pinIcon2 from "../../Assets/icons/pinIcon2.png";
import pinIcon1 from "../../Assets/icons/pin_icon.png";
import pinIcon2 from "../../Assets/icons/pin_fill_icon.png";

const SingleCard = ({
  image,
  name,
  description,
  locked,
  url,
  id,
  handlePinClick,
  currentPinId,
}) => {
  const [searchParams] = useSearchParams();

  const handleClick = async () => {
    if (!locked) {
      try {
        await axios.post(
          constants.SELECT_MODULE_EVENT,
          { selectedModule: id },
          { withCredentials: true }
        );
      } catch (error) {
        handleError(error);
      }

      if (id === searchParams.get("service"))
        window.location.replace(searchParams.get("redirect_url"));
      else window.location.replace(url);
    }
  };
  return (
    <Col
      md={6}
      lg={4}
      className={` px-4 py-3 card position-relative  ${
        locked ? "locked" : "unlock"
      }`}
    >
      <div
        onClick={handleClick}
        className="d-flex align-items-start  text-decoration-none gap-4"
      >
        <div className="d-flex justify-content-center align-items-start h-100 mt-2">
          {locked ? (
            <img src={Lock} alt="" height={24} />
          ) : (
            <img src={image} alt="" height={24} />
          )}
        </div>
        {!locked && (
          <div
            className={`pinIcon ${currentPinId == id ? "active" : ""} `}
            onClick={(e) => handlePinClick(e, id)}
          >
            <img className="img1" src={pinIcon1} alt="" height={20} />
            <img className="img2" src={pinIcon2} alt="" height={20} />
          </div>
        )}
        <div className="flex-grow-1">
          <p className="fw-600 h6 mb-1">{name}</p>
          <p
            className="mb-0 text-muted"
            style={{ fontSize: "14px", width: "95%" }}
          >
            {description}
          </p>
        </div>
      </div>
    </Col>
  );
};

export default SingleCard;
