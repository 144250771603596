export const constants = {
  LANDING_PAGE_URL: process.env.REACT_APP_LANDING_PAGE_URL,
  ENV: process.env.REACT_APP_ENV,
  GET_USER_DATA: "user/accessToProducts",
  SEND_OTP: "user/sendOtpForLogin",
  SSO_LOGIN: "user/authentication-details",
  SELECT_MODULE_EVENT: "user/select-module-event",
  LOGIN_USER: "user/Login",
  FEEDBACK: "feedback/submit",
  FORGET_PASSWORD: "user/password/forgot-password-email",
  RESET_PASSWORD: "user/password/forgot-password-reset",
  PIN_PRODUCT:"user/pin-product"
};

export const sliderArray = [
  { count: 1, color: "#E3170D" },
  { count: 2, color: "#D94D4A" },
  { count: 3, color: "#FC1501" },
  { count: 4, color: "#FF546A" },
  { count: 5, color: "#89CFF0" },
  { count: 6, color: "#007FFF" },
  { count: 7, color: "#0000FF" },
  { count: 8, color: "#29F552" },
  { count: 9, color: "#09BD2D" },
  { count: 10, color: "#09BD2c" },
];

// eslint-disable-next-line no-useless-escape
export const authRegex = /(?:(?:^|.*;\s*)auth\s*\=\s*([^;]*).*$)|^.*$/;

// eslint-disable-next-line no-useless-escape
export const emailRegex =
  /(?:(?:^|.*;\s*)feedbackEmail\s*\=\s*([^;]*).*$)|^.*$/;

export const loginPreferenceOptions = [
  { label: "Password", value: "pass" },
  { label: "OTP", value: "otp" },
  { label: "SSO", value: "sso" },
];

export const titles = {
  logout: "Legistify Logout",
  login: "Legistify Login",
};
