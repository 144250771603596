import React from "react";

import CompanyLogo from "../../Assets/icons/logo_large_black.svg";

import { constants } from "../../utility/constants/constants";
import Container from "../components/Container";

const ThankYouPage = () => {
  return (
    <Container>
      <div className="m-3 m-md-5 w-100">
        <img
          className="mb-5 cursor-pointer"
          onClick={() => window.open(constants.LANDING_PAGE_URL)}
          src={CompanyLogo}
          alt="logo"
        />
        <h5 className="fw-bolder">Thanks for signing!🙏🏻</h5>
        <p className="h6  text-secondary mb-2  ">
          We appreciate your swift signature on the contract. Rest assured, your
          signed copy is securely stored. As soon as all parties complete their
          signatures, you’ll receive an email containing all signed copies along
          with a comprehensive audit trail.
        </p>
        <div className="d-flex f-row  align-items-center justify-content-center h-75 ">
          <div className="d-flex flex-column align-items-center "></div>
        </div>
      </div>
    </Container>
  );
};

export default ThankYouPage;
