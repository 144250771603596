import { Navigate, Outlet } from "react-router-dom";
import { authRegex } from "../../utility/constants/constants";
const RequiredAuth = () => {
  const authCookie = document.cookie.replace(authRegex, "$1");
  if (authCookie === "true") {
    return <Outlet />;
  } else {
    document.cookie = `auth=; Path=/;domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    return <Navigate to="/login" />;
  }
};

export default RequiredAuth;
