import React, { useState } from "react";

import { useKeenSlider } from "keen-slider/react";

import Litigation from "../../Assets/images/LMT.svg";
import Contract from "../../Assets/images/CMT.svg";
import Ipr from "../../Assets/images/IPR.svg";
import Notice from "../../Assets/images/NMT.svg";

import "keen-slider/keen-slider.min.css";

const CarouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <div className="carousel-container w-50 border-right-1">
      <div className="navigation-wrapper m-5 mb-4">
        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide">
            <img className="slider-img" src={Litigation} alt="litigation" />
            <p className="fs-5 fw-bolder mb-1 text-center">Litigation Management</p>
            <p className="fs-6 text-muted text-center">Track & manage all your matters, lawyers, budget, hearings and more</p>
            <a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}corporate/enterprise-legal-management`} className="external-link-btn">
              Learn More
            </a>
          </div>
          <div className="keen-slider__slide number-slide">
            <img className="slider-img" src={Contract} alt="contract" />

            <p className="fs-5 fw-bolder mb-1 text-center">Contract Management</p>
            <p className="fs-6 text-muted text-center">Create and manage your entire contract life-cycle from drafting stage to final execution</p>
            <a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}corporate/enterprise-contract-management`} className="external-link-btn">
              Learn More
            </a>
          </div>
          <div className="keen-slider__slide number-slide">
            <img className="slider-img" src={Ipr} alt="ipr" />

            <p className="fs-5 fw-bolder mb-1 text-center">IPR Management</p>
            <p className="fs-6 text-muted text-center">Track, protect & manage all your IP portfolio and get regular updates</p>
            <a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}corporate/enterprise-ip-portfolio-management`} className="external-link-btn">
              Learn More
            </a>
          </div>
          <div className="keen-slider__slide number-slide">
            <img className="slider-img" src={Notice} alt="notice" />

            <p className="fs-5 fw-bolder mb-1 text-center">Notice Management</p>
            <p className="fs-6 text-muted text-center">Track & manage all your incoming and outgoing legal notices</p>
            <a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}corporate/enterprise-notice-management`} className="external-link-btn">
              Learn More
            </a>
          </div>
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
            if (currentSlide === idx)
              return (
                <div key={idx} className="loaderSlider">
                  <div></div>
                </div>
              );
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CarouselComponent;
