import React from "react";
import { constants } from "../../utility/constants/constants";
import Container from "../components/Container";
import CompanyLogo from "../../Assets/icons/logo_large_black.svg";

import { Link } from "react-router-dom";

const ResetPasswordDone = () => {
  return (
    <Container>
      <div className="m-3 m-md-5 w-100">
        <img className="mb-4 cursor-pointer" onClick={() => window.open(constants.LANDING_PAGE_URL)} src={CompanyLogo} alt="logo" />
        <p className="h4 mb-4">Reset Password</p>
        <p className="text-success fs-5">👍 Your password has been reset. You can now log in to your account using your new password.</p>
        <Link className="fs-5" to="/login">
          Back to login
        </Link>
      </div>
    </Container>
  );
};

export default ResetPasswordDone;
